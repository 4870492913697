import React, { useEffect, useState } from 'react'

import {
  Box,
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react'
import { FaEye, FaAt, FaEyeSlash, FaLock, FaUser } from 'react-icons/fa'

import { Link as RouterLink } from 'react-router-dom'

// Types
import { useDispatch, useSelector } from 'react-redux'
import { UserSignupCredentials } from '../store/auth.types'

import { setSignUpFailure, setSignUpRequest } from '../store/auth.actions'

import useFeedback from '../../shared/ui/feedback/useFeedbackModal'
import Feedback from '../../shared/ui/feedback/Feedback'

import { selectError, selectIsLoading } from '../store/auth.selectors'
import { RoleEnum } from '../../users/store/users.types'

const SignupForm: React.FC = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(selectIsLoading)
  const errorMessage = useSelector(selectError)

  const [show, setShow] = useState<boolean>(false)
  const [credentials, setCredentials] = useState<UserSignupCredentials>({
    password: '',
    confirmPassword: '',
    data: {
      fullName: '',
      email: '',
      role: RoleEnum.GAMER,
    },
  })
  const [passwordError, setPasswordError] = useState<string>('')
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>('')
  const [emailError, setEmailError] = useState<string>('')
  const [nameError, setNameError] = useState<string>('')
  const { title, setTitle, message, setMessage, isOpen, setIsOpen, returnUrl } =
    useFeedback()

  useEffect(() => {
    if (errorMessage && errorMessage.includes('Incorrect password')) {
      setPasswordError(errorMessage)
    } else if (errorMessage && errorMessage.includes('Email not found')) {
      setEmailError(errorMessage)
    } else if (errorMessage) {
      setTitle('Something went wrong')
      setMessage('Please Try Again')
      setIsOpen(true)
    } else {
      setTitle('')
      setMessage('')
      setEmailError('')
      setPasswordError('')
      setIsOpen(false)
    }
  }, [errorMessage])

  const handleClose = () => {
    dispatch(setSignUpFailure({ message: '' }))
  }

  const submit = () => {
    dispatch(setSignUpFailure({ message: '' }))
    dispatch(setSignUpRequest(credentials))
  }

  return (
    <>
      <Box
        p={5}
        shadow="md"
        borderRadius="md"
        background="#0c0c0c"
        minW="260px"
        w="300px"
      >
        <VStack mb={8} mt={4} spacing={5}>
          <Container>
            <FormControl isInvalid={Boolean(nameError)}>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <FaUser />
                </InputLeftElement>
                <Input
                  variant="flushed"
                  placeholder="Name"
                  onChange={(e) => {
                    setNameError('')
                    setCredentials({
                      ...credentials,
                      data: { ...credentials.data, fullName: e.target.value },
                    })
                  }}
                />
              </InputGroup>
              <FormErrorMessage>{nameError}</FormErrorMessage>
            </FormControl>
          </Container>
          <Container>
            <FormControl isInvalid={Boolean(emailError)}>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <FaAt />
                </InputLeftElement>
                <Input
                  variant="flushed"
                  placeholder="Email"
                  onChange={(e) => {
                    setEmailError('')
                    setCredentials({
                      ...credentials,
                      data: { ...credentials.data, email: e.target.value },
                    })
                  }}
                />
              </InputGroup>
              <FormErrorMessage>{emailError}</FormErrorMessage>
            </FormControl>
          </Container>
          <Container>
            <FormControl isInvalid={Boolean(passwordError)}>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <FaLock />
                </InputLeftElement>
                <Input
                  variant="flushed"
                  placeholder="Password"
                  type={show ? 'text' : 'password'}
                  onChange={(e) => {
                    setPasswordError('')
                    setCredentials({ ...credentials, password: e.target.value })
                  }}
                />
                <InputRightElement>
                  <IconButton
                    aria-label="show password"
                    onClick={() => setShow(!show)}
                    icon={!show ? <FaEye /> : <FaEyeSlash />}
                  />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>{passwordError}</FormErrorMessage>
            </FormControl>
          </Container>
          <Container>
            <FormControl isInvalid={Boolean(passwordError)}>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <FaLock />
                </InputLeftElement>
                <Input
                  variant="flushed"
                  placeholder="Confirm Password"
                  type={show ? 'text' : 'password'}
                  onChange={(e) => {
                    setConfirmPasswordError('')
                    setCredentials({
                      ...credentials,
                      confirmPassword: e.target.value,
                    })
                  }}
                />
              </InputGroup>
              <FormErrorMessage>{confirmPasswordError}</FormErrorMessage>
            </FormControl>
          </Container>
          <Container mb={2} centerContent>
            <Text fontSize="sm">
              <Link
                as={RouterLink}
                fontWeight="bold"
                color="brand.50"
                to="/auth/login/"
              >
                Already have an account?
              </Link>
            </Text>
          </Container>
        </VStack>
        <VStack>
          <Container mb={4} centerContent>
            <Button
              isLoading={isLoading}
              loadingText="Please wait"
              isFullWidth
              onClick={submit}
              color="white"
              colorScheme="brand"
            >
              Sign Up
            </Button>
          </Container>
        </VStack>
      </Box>
      <Container mb={2} centerContent>
        <Text fontSize="lg" color="gray">
          <a href="https://www.fitgmr.gg/performance-team-dashboard">Pricing</a>
        </Text>
      </Container>
      <Feedback
        title={title}
        message={message}
        isOpen={isOpen}
        returnUrl={returnUrl}
        onClose={handleClose}
      />
    </>
  )
}

export default SignupForm
